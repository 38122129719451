<template>
  <div v-show="isreload" class="summary_container">
      <div class="select__content">
          <v-select dense outlined rounded solo class="selectbox ml-3 mb-1"
              id="farm" 
              ref="farm" 
              label="사업장✽" 
              v-model="farm"
              :items="farmItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeFarm()"
              @focus="comboFarms()"
              clearable
          />
          <v-btn class="normal-btn elevation-2 mr-1 mt-1"
              id="refreshList" 
              :loading="loading" 
              ref="refreshList" 
              type="submit" 
              @click.prevent="refreshList()">

              <img src="@/assets/icon_search.svg" alt="새로고침" />
              조회
          </v-btn>
        </div>
        <div style="margin-top:-5px;">
            <WattInfo
                :watt_info="watt_info"
                @gowatt="gowatt(null)"
            />
        </div>

      <v-spacer></v-spacer>

      <div v-if="farm.code == 'F23019'">
        <div class="sensor__container">
          <div style="padding:10px 0px 0px 3px;" class="dark_normal">
            <v-icon color="#3a4f3f" size="26">
              mdi-map-marker
            </v-icon>
            <span> {{ items.farm_name }} </span>
            <span> {{ items.room_name }}</span>
          </div>

          <!-- 추후 마을회관 설치 시 이산화탄소 센서값도 추가해야함.-->
          <div style="display:flex; justify-content: center;  ">
            <div class="sensor__info">
              <div class="sensor_name">
                <v-icon color="#3a4f3f" size="18" class="ml-1">
                  mdi-blur
                </v-icon>
                암모니아
              </div> 
              <div class="sensor_value"> 
                <span> {{ items.nh3.current }} </span>
                <span class="unit">ppm</span>
              </div>
            </div>

            <div class="sensor__info">
              <div class="sensor_name">
                <v-icon color="#3a4f3f" size="18" class="mr-1 ml-1">
                  mdi-weather-cloudy
                </v-icon>
                <span style="letter-spacing: -2px;">이산화탄소</span>
              </div>
              <div class="sensor_value">
                <span>  {{ items.co2.current }} </span>
                <span class="unit">ppm</span>
              </div>
            </div>

            <div class="sensor__info">
              <div class="sensor_name">
                <v-icon color="#3a4f3f" size="18" class="ml-1">
                  mdi-alert
                </v-icon>
                황화수소
              </div> 
              <div class="sensor_value">
                <span> {{ items.h2s.current }} </span>
                <span class="unit">ppm</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div outlined class="monitor__content" v-for="building in building_list" :key="building.building_cd">
          <div class="monitor__title">
            <div style="display:flex; width:100%;" >
              <div class="text_building">
                  <div>
                    <v-icon color="#3a4f3f" class="mdi-18px">
                        mdi-chevron-double-right
                    </v-icon>
                    <span class="mr-1">{{ building.building_name }}</span>
                  </div>
                  <div @click="gowatt({code:building.building_cd, name:building.building_name})" style="display:flex; width:130px;" v-if="building.ami_mac !== '' && building.watt !== null">
    
                    <img :src="watt" class="icon_bolt"/>

                    <span style="font-weight:600; margin-left:10px;">{{ building.watt }}
                    <span style="font-size:12px;">KW</span></span>
                  </div>

              </div>
              </div>
          </div>

          <div class="cardRoomInfo">
              <div v-for="room in building.room_list" :key="room.room_code" class="room__content">
                  <div class="roomBox"  @click="clickRoom(building,room)"
                      :class="{lightgray_back:room.temp === '-' && room.nh3 === '-' }">
                      <div class="room__status"
                          :class="{gray_back:room.temp === '-' , blue_back:room.temp <= 31, red_back:room.temp >= 31 || room.hum >= 90 || room.co2 >= 4000 || room.nh3 >= 1 || room.h2s >= 0.02}"></div>

                      <div class="temp__content">
                          
                          <div class="text_name">
                              {{ room.room_name.substring(0,10) }} 
                          </div>

                          <div class="box_sensor_info">
                          <div>
                            <!--온도 -->
                              <div class="box_temp_count">
                                <div>
                                  <img :src="normal_temp" class="icon__temp" v-if="room.temp < 31"/>
                                  <img :src="warning_temp" class="icon__temp" v-else-if="room.temp >= 31"/>
                                  <img :src="notconnect_temp" class="icon__temp" v-else/>
                                </div>

                                <div>
                                  <div
                                      class="text_info ml-3"
                                  >
                                      <span class="text_number" :class="{sos:room.temp >= 31}">{{ room.temp }}</span>
                                      <span
                                      class="text_number_unit"
                                      >℃</span>
                                  </div>
                                  <div
                                      class="text_info ml-3"
                                  >
                                      <span class="text_number feel" :class="{sos:room.temp >= 31}">{{ room.feel }}</span>
                                      <span
                                      class="text_number_unit feel"
                                      >℃</span>
                                  </div>
                                </div>
                              </div>

                            <!--습도-->
                            <div class="box_temp_count" v-if="room.hum !== '-'">
                              <div>
                                  <img :src="hum" class="icon__etc" style="margin-left:-10px;" v-if="room.hum < 90"/>
                                  <img :src="warning_hum" class="icon__etc" style="margin-left:-10px;" v-else-if="room.hum >= 90"/>
                              </div>
                              <div
                                  class="text_info"
                              >
                                  <div class="text_number ml-1" :class="{sos:room.hum >= 90}">{{ room.hum }}</div>
                                  <span
                                  class="text_number_unit" :class="{sos:room.hum >= 90}"
                                  >%</span>
                              </div>
                            </div>

                              <!--이산화-->
                            <div class="box_temp_count" v-if="room.co2 !== '-' ">
                              <div>
                                <img :src="co2" class="icon__etc ml-1" v-if="room.co2 < 4000"/>
                                <img :src="warning_co2" class="icon__etc ml-1" v-else-if="room.co2 >= 4000"/>
                              </div>
                              <div
                                  class="text_info"
                              >
                                  <div class="text_number" :class="{sos:room.co2 >= 4000}">{{ room.co2 }}</div>
                                  <span
                                  class="text_number_unit" :class="{sos:room.co2 >= 4000}"
                                  >ppm</span>
                              </div>
                            </div>

                              <!--암모니아-->
                            <div class="box_temp_count" v-if="room.nh3 !== '-'">
                              <div>
                                  <img :src="nh3" class="icon__etc ml-1" v-if="room.nh3 < 1"/>
                                  <img :src="warning_nh3" class="icon__etc ml-1" v-else-if="room.nh3 >= 1"/>
                              </div>

                              <div
                                  class="text_info"
                              >
                                  <div class="text_number" :class="{sos:room.nh3 >= 1}">{{ room.nh3 }}</div>
                                  <span
                                  class="text_number_unit" :class="{sos:room.nh3 >= 1}"
                                  >ppm</span>
                              </div>

                            </div>

                            <!--황화수소-->
                            <div class="box_temp_count" v-if="room.h2s !== '-'">
                              <div>
                                  <img :src="h2s" class="icon__etc ml-1" v-if="room.h2s < 0.02"/>
                                  <img :src="warning_h2s" class="icon__etc ml-1" v-else-if="room.h2s >= 0.02"/>
                              </div>
                              <div
                                  class="text_info"
                              >
                                  <div class="text_number" :class="{sos:room.h2s >= 0.02}">{{ room.h2s }}</div>
                                  <span
                                  class="text_number_unit" :class="{sos:room.h2s >= 0.02}"
                                  >ppm</span>
                              </div>
                            </div>
                            </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Apis from '@/api/apis'
import VueCookies from "vue-cookies";
import WattInfo from "./child/WattInfo.vue";

import dateUtil from "@/utils/custom/dateProperty.js";

export default {
  name: "AppSummary",
  components: {
    WattInfo,
},
data() {
  return {
      normal_temp: require("@/assets/barometer/normal_temp.svg"),
      notconnect_temp: require("@/assets/barometer/notconnect_temp.svg"),
      warning_temp: require("@/assets/barometer/warning_temp.svg"),
      setting: require("@/assets/barometer/setting.svg"),
      normal_temp2:require("@/assets/monitoring/normal_temp.svg"),
      
      watt: require("@/assets/lightning-bolt.svg"),

      co2: require("@/assets/monitoring/co2.svg"),
      hum: require("@/assets/monitoring/hum.svg"),
      nh3: require("@/assets/monitoring/nh3.svg"),
      h2s: require("@/assets/monitoring/h2s.svg"),
      
      warning_co2: require("@/assets/monitoring/warning_co2.svg"),
      warning_hum: require("@/assets/monitoring/warning_hum.svg"),
      warning_nh3: require("@/assets/monitoring/warning_nh3.svg"),
      warning_h2s: require("@/assets/monitoring/warning_h2s.svg"),

      isreload: false,
      watt_info: {},

      building_list: [],
      farm : {},
      farmItems : [],
      items:{temp:{},hum:{},co2:{},nh3:{},h2s:{},},

      loading: false,
  };
},
async created() {
  let url = new URL(window.location.href);
  // URLSearchParams 객체
  let urlParams = url.searchParams;
  // URLSearchParams.get()
  let cc = urlParams.get('cc');
  let cn = urlParams.get('cn');
  let fc = urlParams.get('fc');
  let fn = urlParams.get('fn');
  
  if (fc) {     // URL파라미터 벋으면 페이지 이동시 URL파라미터 사라지지 않음, 방지를 위한 리로드 : 연구 개선 필요
    // console.log("farm",cc,cn,fc,fn);
  sessionStorage.setItem("paramInfo",JSON.stringify({
      farm:{company_cd: cc, company_name: cn, code: fc, name: fn},
      building:{},})
  );
  history.replaceState({}, null,location.pathname);
  location.reload();
  } else {
      Apis.getIpClient((res) => {
      // console.log(res);  
      let client = res
      Apis.pageCount({ //페이지뷰 카운터
          page_name: `${client.city}/${client.country}/${client.region}/${this.$route.name}`,
          url: this.$route.path,
          ip: client.ip,
          division: "app",
      },() => {  // 정상처리
        // console.log("pageCount 호출",res);
      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("pageCount 호출 오류",err);
      });
    }).catch( (err) => {  // API 오류 처리
          console.log("getIPI 호출 오류",err)
      }) 

      this.isreload = true;
      await this.comboFarms();
      let cookie_info = VueCookies.get("cardroom_info");

      let paramInfo = JSON.parse(sessionStorage.getItem("paramInfo"));
      if (paramInfo) {
          // console.log("파람",cookie_info);
          cookie_info = paramInfo;
          sessionStorage.removeItem("paramInfo");
      }

      if (cookie_info) {
          // console.log("쿠키",cookie_info);
          this.farm = cookie_info.farm || {};
          this.building = cookie_info.building || {};
      } else {
          this.farm = this.farmItems[0];
      }
      this.refreshList();
      }
},
methods: {
  async refreshWeatherInfo() {
        await Apis.monitoringWeather({
            farm_cd: this.farm.code,
            weather: false,
        } ,(res) => {
            this.watt_info = res.data;
        });
    },

  gowatt(building){

    this.$router.push({
        name: "WattageDetail",
        params: {
          company:{code:this.farm.company_cd, name:this.farm.company_name},
          farm:this.farm,
          building: building,
        }
    });
    // console.log("Params : ", this.$route.code);
  },
  refreshList() {
      this.$store.commit("resMessage","");
      if (!this.farm || !this.farm.code ) {
          alert("축사/건물/구역를 선택하세요");
          this.$refs.building.focus();
          return;
      }
      this.loading = true;
      this.standSensor();
      this.refreshWeatherInfo();

      Apis.farmSummaryViewForLite({
          farm_cd: this.farm && this.farm.code,
          building_cd: this.building && this.building.code || "",
          stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
      } ,(res) => {  // 정상처리
          this.$store.commit("resMessage",res.message);
          this.building_list = res.data;
          //console.log(res.data);
          this.loading = false;
          let cookie_info = {farm: this.farm, building: this.building };
          VueCookies.set("cardroom_info" ,cookie_info,"30d");
      }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("farmSummaryViewForLite 호출 오류",err);
          this.loading = false;
      })
  },
  standSensor(){
    Apis.roomMonitoringSummary({
        room_cd : "R23031",
        stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      } ,(res) => {  // 정상처리
        if (!res.result)  this.$store.commit("resMessage",res.message);
      
        this.items = res.data;
        // console.log(this.items);

      }).catch( (err) => {  // API 오류 처리
        this.$store.commit("resMessage",err);
        console.log("roomMonitoringSummary 호출 오류",err);
      });
  },
  clickRoom(building,room) {
      // console.log("click",building,room);
      this.$router.push({
          name: "Barometer",
          params: { 
          company: {code: building.company_cd, name: building.company_name},
          farm: {code: building.farm_cd, name: building.farm_name},
          building: {code: building.building_cd, name: building.building_name},
          room: {code: room.room_cd, name: room.room_name + " " + building.farm_name },
          }
      });
  },
  async comboFarms() {
      await Apis.comboFarms({
          company_cd: "",
          stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
          },(res) => {  
          if (res.result) {
              this.farmItems = [];
              for (let i in res.data) {
              this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
              }  
          } else {
              alert(res.message);
          }
          }).catch( (err) => {  // API 오류 처리
              console.log("comboFarms API 호출 오류",err)
              // alert(err);
          }
      ) 
  },
  changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.company_cd) {
          this.building = {};
      }
      this.buildingItems = [];
      this.refreshList();
      },
      async comboBuildings() {
      await Apis.comboBuildings({
          company_cd:this.company &&  this.company.code || "",
          farm_cd: this.farm && this.farm.code || "",
          stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
          },(res) => {  
          if (res.result) {
              // console.log(res.data)
              this.buildingItems = [];
              for (let i in res.data) {
              this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
              }  
          } else {
              alert(res.message);
          }
          }).catch( (err) => {  // API 오류 처리
              console.log("comboBuildings API 호출 오류",err)
          }
      ) 
  },
  },
};
</script>

<style lang="scss" scoped>
.summary_container{
  overflow-x: hidden;
  margin-top:15px;
}
.select__content{
  display:flex;
}
.selectbox{
  width:80%;
  height:40px;
}
.normal-btn{
  border-radius: 150px;
  margin-left:5px;
  letter-spacing: -0.72px;
  width:15%;
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.monitor__content , .sensor__container, .sensor__info{
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #a3a3a3;
  background-color:#ffffffcd;
  border-radius: 10px;
  margin:10px;
  margin-bottom: 20px;
}
.sensor__container{
  letter-spacing: -0.75px;
  font-size:12pt;
  background:#f4f4f4;
}
.sensor__info{
  width:25vw;
  height:65px;
  line-height:25px;
  padding:3px;
}
.sensor_name{
  font-size: 10pt;
  letter-spacing: -0.75px;
  text-align:left;
}
.sensor_value{
  font-size:13pt;
  margin-left:5px;
  display:flex;
}
.sensor_value span{
  width:50px;
  text-align:center;
}

.unit{
  font-size:11pt;
}
.cardRoomInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}
.text_building {
  display:flex;
  padding:5px;
  font-size: 12pt;
  font-weight: 700;
  letter-spacing: -0.74px;
  text-align: left;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  color: #3a4f3f;
  border:1px solid #3a4f3f;
  background-color:white;
  margin:10px;
}
.roomBox {
  display:flex;
  width: 165px !important;
  height: 240px !important;
  margin:10px 3px 10px 15px;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
  background-color: white;
  cursor: pointer;
}
.room__status{
  height: 240px;
  margin-right:5px;
  width:7px;
  border-radius:4px 0px 0px 4px;
}
.text_name {
  margin:5px 5px;
  font-size:15pt !important;
  font-weight: 700 !important;
  letter-spacing: -1.25px;
  text-align: left !important;
  margin-bottom: 15px !important;
}
.temp__content{
  margin-top:5px;
}
.box_sensor_info{
  display:flex;
  height:175px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box_temp_count{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:-5px;
}
.text_info{
  display:flex;
  padding-left:1px;
  margin-left:5px;
  letter-spacing: -0.75px;
}
.text_number{
  text-align:right;
  width:70px;
  margin-right:10px;
  font-size:15pt;
  font-weight:600;
  margin-top:-5px;
  // box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.8);
}
.feel{
  font-size:12pt;
}
.text_number_unit{
  font-size:9pt;
  letter-spacing: -2px;
  margin-right:5px;
}
.icon__etc{
  width:30px;
  height:30px;
  margin-right:10px;
}
.icon__temp{
  margin-right:10px;
  width:20px;
  height:45px;
}
.dark_normal{
  color:#10562e;
}
.sos{
  color:#88191a;
}
.lightgray_back{
  background-color:#f4f4f4;
}
.gray_back{
  background-color:#dddddd;
  // color:#e4e4e4;
}
.blue_back{
  background-color:#16713e;
}
.red_back{
  background-color: #99191a;
}

.icon_bolt{
  width:25px;
  filter: invert(77%) sepia(86%) saturate(346%) hue-rotate(340deg) brightness(100%) contrast(94%);
}
.weather__title{
  text-align:center;
  font-size:13px;
  font-weight:bold;
}
@media screen and (max-width: 390px) {
.roomBox {
  margin:10px 5px 10px 15px;
  width: 40vw !important;
  height: 220px !important;
}
.text_name{
  margin-bottom: 5px !important;
}
.text_number{
  width:50px;
}
.room__status{
  height: 220px !important;
}
}
@media screen and (max-width: 320px) {
.roomBox {
  width: 165px !important;
  height: 220px !important;
}
.room__status{
  height: 220px !important;
}
}

</style>
