<template>
  <div id="weather__info">
    <div class="watt__content ml-1" @click="$emit('gowatt')"  v-if="watt_info.watt">
      <div class="weather__title"> 
        <span> 실시간 15분 전력 </span>
      </div>
      <img :src="watt" class="icon_bolt"/>

      <div class="progressBar">
        <div id="bar" class="innerbar" :style="{width:(watt_info.watt/0.5)*100 + '%'}"></div>
      </div>

        <span style="font-weight:600; margin-left:10px;"> {{ watt_info.watt }}
        <span style="font-size:12px;">KW</span></span>
      </div>
    </div>
</template>

<script>
export default {
  name: "WattInfo",
  props: {
    watt_info: {},
  },
  data() {
    return {
      watt: require("@/assets/lightning-bolt.svg"),

    };
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>
<style lang="scss" scoped>
#weather__info{
  letter-spacing: -1px;
  justify-content: center;
  margin:15px 10px 10px 5px;
}
.watt__content{
  letter-spacing: -1.5px;
  display:flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:40px;
  line-height:40px;
  border: 1px solid #a3a3a3;
  background-color:#fefff9;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  color:#3a4f3f;
  border-radius: 15px;
  padding:3px;
  cursor:pointer;
  margin-bottom: 13px;
}
.icon_bolt{
  width:25px;
  filter: invert(77%) sepia(86%) saturate(346%) hue-rotate(340deg) brightness(100%) contrast(94%);
}
.progressBar {
  max-width: 130px;
  width: 90%;
  height: 8px;
  border-radius: 3px;
  background: linear-gradient(#6fa6d66c, #7db1df54);
}
.innerbar {
  max-width: 130px;
  height: 100%;
  text-align: right;
  height: 8px; /* same as #progressBar height if we want text middle aligned */
  width: 5%;
  border-radius: 3px;
  background: linear-gradient(#e4e8d4, #57c1a0);
}
.weather__title{
  text-align:center;
  font-size:14px;
  font-weight:bold;
}
</style>
